/*
Helper CSS
*/

@for $i from 1 through 500 {
  .z-index-#{$i} {
    z-index: #{$i} !important;
  }
}

@for $i from 1 through 50 {
  .border-radius-#{$i} {
    border-radius: #{$i}$px !important;
  }
  .border-top-radius-#{$i} {
    border-top-left-radius: #{$i}$px !important;
    border-top-right-radius: #{$i}$px !important;
  }
  .border-top-left-radius-#{$i} {
    border-top-left-radius: #{$i}$px !important;
  }
  .border-top-right-radius-#{$i} {
    border-top-right-radius: #{$i}$px !important;
  }
  .border-bottom-radius-#{$i} {
    border-bottom-left-radius: #{$i}$px !important;
    border-bottom-right-radius: #{$i}$px !important;
  }
  .border-bottom-left-radius-#{$i} {
    border-bottom-left-radius: #{$i}$px !important;
  }
  .border-bottom-right-radius-#{$i} {
    border-bottom-right-radius: #{$i}$px !important;
  }
}

@for $i from 1 through 100 {
  .width-#{$i} {
    width: #{$i}$percentage !important;
  }
  .min-width-#{$i} {
    min-width: #{$i}$percentage !important;
  }
  .max-width-#{$i} {
    max-width: #{$i}$percentage !important;
  }
}

@for $i from 1 through 500 {
  .width-#{$i}px {
    width: #{$i}$px !important;
  }
  .min-width-#{$i}px {
    min-width: #{$i}$px !important;
  }
  .max-width-#{$i}px {
    max-width: #{$i}$px !important;
  }
}

@for $i from 1 through 100 {
  .height-#{$i} {
    height: #{$i}$percentage !important;
  }
  .min-height-#{$i} {
    min-height: #{$i}$percentage !important;
  }
  .max-height-#{$i} {
    max-height: #{$i}$percentage !important;
  }
}

@for $i from 1 through 500 {
  .height-#{$i}px {
    height: #{$i}$px !important;
  }
  .min-height-#{$i}px {
    min-height: #{$i}$px !important;
  }
  .max-height-#{$i}px {
    max-height: #{$i}$px !important;
  }
}

/* Set top 0 */
.top-0 {
  top: 0 !important;
}

/* Set right 0 */
.right-0 {
  right: 0 !important;
}

/* Set bottom 0 */
.bottom-0 {
  bottom: 0 !important;
}

/* Set left 0 */
.left-0 {
  left: 0 !important;
}
