/* Tema Delivery Azul */ 

@import "variables";

.back-ground-pagamento-delivery-azul {
    background-color: $azul_medio !important;
}

.card-danger-pagamento-deivery-azul.card-outline {
    border-top: 3px solid $azul_medio !important;
}

.header-delivery-azul {
    background-color: $azul_medio !important;
}

.card-danger-delivery-azul:not(.card-outline) > .card-header {
    background-color: $azul_medio !important;
    border-bottom: 0 !important;
}

.footer-delivery-azul {
    background-color: $azul_medio !important;
}

.fundo-gradiente-delivery-azul-carrinho-itens {
    background: linear-gradient(180deg,rgba(0,0,0,0) 0,rgba(0,0,0,0) 50%,rgba(0,0,0,0.6)) !important;
    background-color: $azul_medio !important;
}

.login-nav-delivery-azul {
    background:  $azul_escuro !important;
}

.carrinho-seta-delivery-azul::before {
    content: '';
    display: "block";
    position: "absolute";
    bottom: "100%";
    width: 0;
    height: 0;
    left: 0;
    right: 0;
    margin: "0 auto";
    border: "15px solid transparent";
    border-bottom-color: $azul_medio !important;
}

.carrinho-seta-delivery-azul::after {
    content: '';
    display: "block";
    position: "absolute";
    bottom: "100%";
    width: 0;
    height: 0;
}

.bg-danger-delivery-azul {
    background-color: $azul_medio !important;
    color: $_white !important
}

.btn-danger-delivery-azul {
    background-color: $azul_medio !important;
    border-color: $azul_medio !important;
    color: $_white !important;
}

.btn-danger-delivery-azul-versao-2 {
    background-color: $amarelo !important;
    border-color: $amarelo !important;
    color: $azul_medio !important;
}

.text-red-delivery-azul {
    color: $azul_medio !important;
}

.btn-danger-login-delivery-azul {
    color: $_white !important;
    background-color: $azul_medio !important;
    border-color: $azul_medio !important;
}

.btn-danger-buscar-cep-delivery-azul {
    color: $_white !important;
    background-color: $azul_medio !important;
    border-color: $azul_medio !important;
}

.icheck-danger-delivery-azul > input:first-child:checked + label::before {
    background-color: $azul_medio !important;
    border-color: $azul_medio !important;
}

.card-danger-montagem-delivery-azul.card-outline {
    border-top: 3px solid $azul_medio !important;
}

.callout-danger-montagem-delivery-azul {
    border-left-color: $azul_medio !important;
}

.background-inexistente-delivery-azul {
    background-color: $_white !important;
}

/*menu-btn __burger*/

.menu-btn__burger-delivery-azul, .menu-btn__burger-delivery-azul::before, .menu-btn__burger-delivery-azul::after {
    content: '' !important;
    background: $azul_medio !important;
    box-shadow: 0 2px 5px rgba(3, 30, 102, 0.2) !important;
}

.menu-btn.open .menu-btn__burger-delivery-azul {
    background: transparent !important;
    box-shadow: none !important;
}