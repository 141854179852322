/*
Custom CSS
*/

html {
  font-size: 12px;
}

body {
  background-color: $body-bg !important;
}

html, body, #app, #app > div {
  position: absolute;
  width: 100% !important;
  height: 100% !important;
  margin: 0 !important;
  overflow: hidden;
}

@media screen and (min-width: 320px) {
  html {
    font-size: calc(14px + 6 * ((100vw - 320px) / 680));
  }
}

@media screen and (min-width: 1000px) {
  html {
    font-size: 18px;
  }
}

.font-mont-extrabold {
  font-family: 'Montserrat', sans-serif !important;
  font-weight: 900 !important;
}

textarea, textarea:before, textarea:after {
  -webkit-user-select: initial;
  -khtml-user-select: initial;
  -moz-user-select: initial;
  -ms-user-select: initial;
  user-select: initial;
}

* {
  user-select: none;
  -khtml-user-select: none;
  -o-user-select: none;
  -moz-user-select: -moz-none;
  -webkit-user-select: none;
}

input, input:before, input:after {
  -webkit-user-select: initial;
  -khtml-user-select: initial;
  -moz-user-select: initial;
  -ms-user-select: initial;
  user-select: initial;
}

.overlay-custom {
  background-color: rgba(0, 0, 0, 0.7);
  display: block;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1052;
}

/*
Loading
*/
.overlay {
  background-color: #000;
  display: block;
  height: 100%;
  left: 0;
  opacity: .7;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1052;
}

.mv-toten {
  width: 100%;
  height: 89vh;
  z-index: $zindex-fixed;
}

.mv-toten-bg-vinho {
  background: $vinho;
}

.mv-toten-bg-vinho-gradiente {
  background: linear-gradient(90deg, rgb(89, 2, 16) 0%, rgb(102, 3, 18) 1%, rgb(141, 5, 29) 5%, rgb(150, 6, 31) 50%, rgb(141, 5, 29) 95%, rgb(102, 3, 18) 99%, rgb(89, 2, 16) 100%);
}

.mv-toten-text {
  animation-delay: 7s;
  margin-top: 30px !important;
}

.mv-toten-title {
  margin-top: 30px !important;
}

.mv-toten-text-color {
  color: $vermelho_Menew !important;
}

.logo-menew-svg {
  fill: $vermelho_Menew !important;
}

.fill-white {
  fill: #fff;
}

.fill-vermelho_menew {
  fill: $vermelho_Menew;
}

.mv-footer button {
  //width: 160px;
  border-radius: 30px;
  font-size: 12px;
}

/*Navbar*/
.mv-toten-navbar {
  margin: 0 auto;
  padding: 10px;
  background-color: $vermelho_Menew !important;
}

.mv-toten-navbar a, .mv-toten-navbar span {
  color: $body-bg;
  text-decoration: none;
  background-color: transparent;
}

.logo-menew-2-svg {
  fill: $body-bg !important;
}

.mv-logo-nav {
  display: block;
  width: 150px;
}

.mv-image-itens {
  width: 200px !important;
  height: 150px !important;;
}

.box-radius > div {
  border-radius: 1em;
}

.box-radius > div > a {
  border-bottom-left-radius: 1em;
  border-bottom-right-radius: 1em;
}

@media (max-width: 767.98px) {
  .small-box .icon {
    display: block !important;
  }
}

.small-box .icon {
  position: relative;
  margin: 0 auto;
  right: 0px;
  top: 0px;
  transition: all .3s linear;
}

.small-box .icon img {
  max-width: 160px;
}

.slider .item {
  border-radius: 0.5em;
}

.slider .img-slider {
  width: 100px;
  height: 100px;
}

.mv-toten-itens {
  height: auto;
  margin-top: 5vh;
}

/*NavbarFIM*/

/*Footer*/
.mv-toten-footer {
  position: fixed !important;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
}

.mv-toten-footer .section-1 {
  border-left: 10px solid transparent;
  border-bottom: 10px solid #fff;
  border-right: 10px solid transparent;
  margin: -20px auto;
}

.mv-toten-footer .section-2 {
  border-top-left-radius: 14px;
  background-color: #f4f4f4;
  border-top-right-radius: 14px;
}

.mv-toten-footer .section-2 button {
  border-radius: 30px;
  font-size: 10px;
}

/*FooterFIM*/

/*Carrousel*/

.BrainhubCarousel__custom-arrowLeft, .BrainhubCarousel__custom-arrowRight {
  z-index: 100;
  position: relative;
}

.BrainhubCarousel__custom-arrowRight {
  right: 0;
}

li.BrainhubCarouselItem--active > img {
  opacity: 1 !important;
}

.categorias-item {
  opacity: 0.3;
}

.arrow-up {
  position: relative;
  height: 0;
  width: 0;
  margin: 0 auto;
  margin-top: -15px;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-bottom: 15px solid #FFF;
}

/*CarrouselFIM*/

/*Slider*/
.slick-prev:before,
.slick-next:before {
  font-family: 'slick';
  font-size: 20px;
  line-height: 1;

  opacity: .75;
  color: #000;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

hr.underline {
  height: .25rem;
  width: 100%;
  margin: 0;
  background: $vermelho_Menew;
  border: none;
  transition: .3s ease-in-out;
}

.owl-theme .owl-nav.disabled + .owl-dots {
  margin-top: -30px !important;
}

.toque {
  color: #FFF;
  background: #A80000;
  border: none;
  padding: 1rem 20px 1rem 20px;
  border-radius: 20px;
  font-size: 14px;
}

/*SliderFIM*/

/*Subgrupos*/

.card-danger:not(.card-outline) > .card-header {
  background-color: $subgrupo !important;
  border-bottom: 0 !important;
}

.subgrupos, .scroll {
  overflow: auto !important;
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  top: 0;
}

.scroll-position-initial {
  position: initial !important;
}

//.scroll::-webkit-scrollbar-track
//{
//  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
//  background-color: #F5F5F5;
//}

.scroll::-webkit-scrollbar {
  width: 0px;
  background-color: #F5F5F5;
}

//.scroll::-webkit-scrollbar-thumb
//{
//  background-color: #000000;
//  border: 2px solid #555555;
//}


.card {
  border: 0 !important;
}

.card-body > h4 {
  background: #f4f4f4;
  border: 1px solid #f4f4f4;
  color: #444;
  margin: -10px;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
}

.subgrupos-icon {
  background-color: #f4f4f4;
  border-radius: 100%;
  height: 28px;
  width: 28px;
  text-align: center;
}

.subgrupos-icon > i.fa-minus {
  color: $vermelho_Menew;
}

.subgrupos-icon > i.fa-plus {
  color: $_green;
}

.subgrupos-icon2 {
  background-color: #f4f4f4;
  border-radius: 100%;
  height: 42px;
  width: 42px;
  text-align: center;
}

.subgrupos-icon2 > i.fa-minus {
  color: $vermelho_Menew;
}

.subgrupos-icon2 > i.fa-plus {
  color: $_green;
}

/*SubgruposFim*/


/*
Style Login
*/

@import "../../assets/sass/variables";

.login-body {
  background-color: #f1f1f1;
}

/*Nav*/
.login-nav {
  position: fixed;
  background: $menew__bg_reg;
  height: 100%;
  width: 100%;
  clip-path: circle(100px at 100% -40%);
  -webkit-clip-path: circle(100px at 100% -40%);
  transition: all 1s ease-out;
  z-index: 20;
}

.login-nav_red_old {
  background: #A80000;
}

.login-nav.open {
  clip-path: circle(2000px at 80% -10%);
  -webkit-clip-path: circle(2000px at 80% -10%);
  transition: all 1.5s ease-out;
}

@media screen and (min-height: 520px) {
  .login-nav-links {
    align-items: center;
    justify-content: center;
    flex-direction: column;
    display: flex;
  }
}

@media screen and (max-height: 520px) {
  .login-nav-links {
    align-items: center;
    justify-content: center;
    display: flex;
  }
}

/*Bar*/
#menu {
  background-color: white;
  width: 35px;
  margin: 15px;
}

.bar {
  width: 100%;
  height: 5px;
  background-color: $menew__bg_reg;
  display: block;
  border-radius: 5px;
  transition: 0.3s ease;
}

#bar1 {
  transform: translateY(-4px);
}

#bar3 {
  transform: translateY(4px);
}

.open #bar1 {
  transform: translateY(4px) rotateZ(-45deg);
}

.open #bar2 {
  opacity: 0;
}

.open #bar3 {
  transform: translateY(-6px) rotate(45deg);
}


/*menu-btn __burger*/
.menu-btn {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 15px;
  width: 35px;
  transition: all .5s ease-in-out;
}

.menu-btn__burger,
.menu-btn__burger::before,
.menu-btn__burger::after {
  content: '';
  position: absolute;
  width: 40px;
  height: 6px;
  background: $menew__bg_reg;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(168, 0, 0, .2);
  transition: all .5s ease-in-out;
}

.menu-btn__burger_white,
.menu-btn__burger_white::before,
.menu-btn__burger_white::after {
  background: white !important;
}

.menu-btn__burger::before {
  transform: translateY(-10px);
}

.menu-btn__burger::after {
  transform: translateY(10px);
}

.menu-btn.open .menu-btn__burger {
  transform: translateX(50px);
  background: transparent !important;
  box-shadow: none;
}

.menu-btn.open .menu-btn__burger::before {
  transform: rotate(45deg) translate(-35px, 35px);
}

.menu-btn.open .menu-btn__burger::after {
  transform: rotate(-45deg) translate(-35px, -35px);
}

.custom-select2__control {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.12), 0 4px 5px rgba(0, 0, 0, 0.24);
  padding: 10px;
  padding-left: 60px;
}


/*Slick custom*/
.custom-slider {
  .slick-center {
    transform: scale(1.3);
    opacity: 1 !important;
  }

  .slick-slide {
    transition: all .3s ease;
    padding: 4px;
    opacity: .8;
  }
}

/*credit cards*/
.rccs__card {
  height: 130px;
  margin: 0 auto;
  position: relative;
  transform-style: preserve-3d;
  transition: all 0.4s linear;
  width: 200px;
}

.rccs__number {
  clear: both;
  font-family: "Consolas", "Courier", monospace;
  font-size: 15px;
  left: 10%;
  position: absolute;
  top: 35%;
}

.rccs__name {
  bottom: 15%;
  font-family: "Consolas", "Courier", monospace;
  font-size: 11px;
  left: 10%;
  line-height: 1;
  overflow: hidden;
  position: absolute;
  text-align: left;
  text-overflow: ellipsis;
  text-transform: uppercase;
  width: 60%;
}

.rccs__cvc {
  color: #222;
  font-family: "Consolas", "Courier", monospace;
  font-size: 14px;
  left: 63%;
  line-height: 1;
  position: absolute;
  top: 42%;
}

.rccs {
  margin: 0 auto;
  perspective: 1000px;
  width: auto;
}


/*Custom Select*/
.custom-select__control, .custom-select__indicators {
  border-width: 0 !important;
  border-radius: 10px !important;
  background-color: #e6e6e6 !important;
  height: calc(1.6em + 0.6rem + 2px) !important;
  min-height: calc(1.6em + 0.6rem + 2px) !important;
  font-size: 0.875rem !important
}
