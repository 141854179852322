// Body
$body-bg: #f8fafc;

// Typography
$font-family-sans-serif: 'Nunito', sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;

// Colors
$blue: #3490dc;
$indigo: #6574cd;
$purple: #9561e2;
$pink: #f66d9b;
$red: #e3342f;
$orange: #f6993f;
$yellow: #ffed4a;
$green: #38c172;
$teal: #4dc0b5;
$cyan: #6cb2eb;
$vinho: #7f0e24;
$nav: #c0272d;
$vermelho_Menew: #A80000;
$subgrupo: #c0272d;

/*_Colors*/
$_blue: #007bff;
$_indigo: #6610f2;
$_purple: #6f42c1;
$_pink: #e83e8c;
$_red: #dc3545;
$_orange: #fd7e14;
$_yellow: #ffc107;
$_green: #28a745;
$_teal: #20c997;
$_cyan: #17a2b8;
$_white: #ffffff;
$_gray: #6c757d;
$_gray-dark: #343a40;
$_primary: #007bff;
$_secondary: #6c757d;
$_success: #28a745;
$_info: #17a2b8;
$_warning: #ffc107;
$_danger: #dc3545;
$_light: #f8f9fa;
$_dark: #343a40;

/*_Symbols*/
$percentage: unquote("%");
$px: unquote("px");

/*_Layout Menew Colors*/
$menew__bg_reg: #ff1200;

/*_Delivery Azuç Colors*/
$azul_claro: #3498db;
$azul_medio : #012a86;
$azul_escuro: #04213e;
$amarelo: #ffd647;
