// Fonts
//@import url('https://fonts.googleapis.com/css?family=Nunito');
//@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,400i,700');
//@import url('https://fonts.googleapis.com/css?family=Work+Sans:100,200,300,400,700,800');
//@import url('https://fonts.googleapis.com/css?family=Montserrat:300,400,500,700,800,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap');

@import url('https://code.ionicframework.com/ionicons/2.0.1/css/ionicons.min.css');
@import "~@fortawesome/fontawesome-free/css/all.min.css";

// Bootstrap
@import '~bootstrap/scss/bootstrap';

// Variables
@import 'variables';

// Helpers
@import "helpers";

// Custom
@import "custom";

/*Layout Menew*/
@import "layout_menew";

// Delivery Azul
@import "delivery_azul";

// animate.css
@import "~animate.css/animate.min.css";

// Dependências
@import "~admin-lte/plugins/icheck-bootstrap/icheck-bootstrap.min.css";
@import "~admin-lte/dist/css/adminlte.min.css";
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
@import "~react-toastify/dist/ReactToastify.min.css";
@import "~react-sweet-progress/lib/style.css";
