/*
Css for Menew Layout
*/

@import "variables";

body {
  font-family: 'Poppins', sans-serif !important;
  font-weight: 400 !important;
}

/*_background colors*/
.menew__bg-red {
  background-color: $menew__bg_reg !important;
}

/*_btn colors*/
.menew__btn-danger {
  color: #fff !important;
  background-color: $menew__bg_reg !important;
  border-color: $menew__bg_reg !important;
}

/*_text colors*/
.menew__text-red {
  color: $menew__bg_reg !important;
}

@media (max-width: 544px) {
  .text-responsive-xs {
    font-size: 0.75rem !important;
  }
  .text-responsive-xs-right {
    text-align: right !important;
    font-size: 0.75rem !important;
  }
  .text-responsive-xs-left {
    text-align: left !important;
    font-size: 0.75rem !important;
  }
  .text-responsive-xs-center {
    text-align: center !important;
    font-size: 0.75rem !important;
  }
}

@media (min-width: 544px) {
  .text-responsive-sm {
    font-size: 0.875rem !important;
  }
  .text-responsive-sm-right {
    text-align: right !important;
    font-size: 0.875rem !important;
  }
  .text-responsive-sm-left {
    text-align: left !important;
    font-size: 0.875rem !important;
  }
  .text-responsive-sm-center {
    text-align: center !important;
    font-size: 0.875rem !important;
  }
}

@media (min-width: 768px) {
  .text-responsive-md {
    font-size: 1rem !important;
  }
  .text-responsive-md-right {
    text-align: right !important;
    font-size: 1rem !important;
  }
  .text-responsive-md-left {
    text-align: left !important;
    font-size: 1rem !important;
  }
  .text-responsive-md-center {
    text-align: center !important;
    font-size: 1rem !important;
  }
}

@media (min-width: 1012px) {
  .text-responsive-lg {
    font-size: 1.25rem !important;
  }
  .text-responsive-lg-right {
    text-align: right !important;
    font-size: 1.25rem !important;
  }
  .text-responsive-lg-left {
    text-align: left !important;
    font-size: 1.25rem !important;
  }
  .text-responsive-lg-center {
    text-align: center !important;
    font-size: 1.25rem !important;
  }
}

@media (min-width: 1280px) {
  .text-responsive-xl {
    font-size: 2rem !important;
  }
  .text-responsive-xl-right {
    text-align: right !important;
    font-size: 2rem !important;
  }
  .text-responsive-xl-left {
    text-align: left !important;
    font-size: 2rem !important;
  }
  .text-responsive-xl-center {
    text-align: center !important;
    font-size: 2rem !important;
  }
}

/* Set the font weight to normal */
.text-normal {
  font-weight: 400 !important;
}

.Toastify__toast--error {
  background: $menew__bg_reg !important;
}
